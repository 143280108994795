import { Pagination } from '@mui/material';
import { useState } from 'react';
import { Form } from 'react-bootstrap';

interface AdvanceTableCPaginationProps {
  setCurrentPage?: (page: number) => void;
  count?: number;
  page?: number;
}

const customStyle = {
  '& .MuiButtonBase-root': {
    fontSize: '0.8rem',
    lineHeight: '1.5rem',
    color: '#252C32',
    '&.Mui-selected': {
      color: '#fff',
      borderRadius: '0.375rem',
      backgroundColor: '#8200BF',
      '&:hover': {
        backgroundColor: '#8200BF'
      }
    },
    '&:hover': {
      backgroundColor: '#e3e6ed'
    }
  }
};

const AdvanceTableCPagination = ({
  setCurrentPage = () => {},
  count = 1,
  page = 1
}: AdvanceTableCPaginationProps) => {
  const [inputPage, setInputPage] = useState(page);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setInputPage(Number(value));
    }
  };

  const handlePageJump = () => {
    if (inputPage > 0 && inputPage <= count) {
      setCurrentPage(inputPage);
    }
  };

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '15px',
        paddingBottom: '16px',
        paddingLeft: '12px'
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <Form.Group>
          <Form.Control
            type="text"
            value={inputPage || ''}
            onChange={handleInputChange}
            onBlur={handlePageJump}
            onKeyPress={e => {
              if (e.key === 'Enter') handlePageJump();
            }}
            style={{
              width: '80px'
            }}
            autoComplete="off"
          />
        </Form.Group>

        <span style={{ fontSize: '0.8rem', color: '#6c757d' }}>of {count}</span>
      </div>
      <Pagination
        sx={customStyle}
        shape="rounded"
        onChange={(e, val) => setCurrentPage(val)}
        count={count}
        page={page}
      />
    </div>
  );
};

export default AdvanceTableCPagination;
