/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-escape */
import { Form, Row, Col } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import { UilCalendarAlt } from '@iconscout/react-unicons';
import classNames from 'classnames';
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';

import 'flatpickr/dist/flatpickr.css';
import Button from 'components/base/Button';
import useCountryUnicode from 'hooks/useCountryUnicode';
import usersService from 'services/users';
import { showAlert } from 'store/alert/alert.thunk';
import PhoneNumberInput from 'components/forms/PhoneNumberInput';
import rolesService from 'services/roles';
import countryService from 'services/country';
import regionService from 'services/region';
import areaService from 'services/area';
import legalEntityService from 'services/legalEntity';
import usersStatusService from 'services/usersStatus';
import ImageUpload from 'components/forms/ImageUpload';

interface CreateUsersTableProps {
  setOpenUsers: (show: boolean) => void;
  selectedUsersId?: any;
  refetch?: any;
}

const CreateUsersTable = ({
  setOpenUsers,
  selectedUsersId,
  refetch
}: CreateUsersTableProps) => {
  const { t, i18n } = useTranslation();

  const genderOption = [
    {
      value: 'MALE',
      label: t('male')
    },
    {
      value: 'FEMALE',
      label: t('female')
    }
  ];

  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, control, reset } = useForm();
  const [roleOption, setRoleOption] = useState([]);
  const [countryOption, setCountryOption] = useState([]);
  const [areaOption, setAreaOption] = useState([]);
  const [regionOption, setRegionOption] = useState([]);
  const [legalEntityOption, setLegalEntityOption] = useState([]);
  const [userStatusOption, setUserStatusOption] = useState([]);

  const dispatch: Dispatch<any> = useDispatch();
  const unicode = useCountryUnicode();

  //GET ROLE
  useQuery(['GET_USER_STATUS'], async () => {
    const res = await usersStatusService.getList({ offset: 0, limit: 100 });
    const options = res?.data?.user_statuses?.map((option: any) => ({
      value: option.id,
      label: option.name
    }));
    setUserStatusOption(options);
  });

  //GET Legal Entity to options
  useQuery(['GET_LEGAL_ENTITY'], async () => {
    await legalEntityService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.legal_entities?.map((option: any) => ({
          value: option.id,
          label: option.name
        }));
        setLegalEntityOption(options);
      });
  });

  //GET ROLE
  useQuery(['GET_ROLE'], async () => {
    const res = await rolesService.getList({
      offset: 0,
      limit: 100,
      system_level: 'HOLDING'
    });
    const options = res?.data?.roles?.map((role: any) => ({
      value: role.id,
      label: role.name
    }));
    setRoleOption(options);
  });

  //GET Country to options
  useQuery(['GET_COUNTRY'], async () => {
    await countryService.getList({ offset: 0, limit: 100 }).then((res: any) => {
      const options = res?.countries?.map((country: any) => ({
        value: country.id,
        label: country.name?.[i18n?.language]
      }));
      setCountryOption(options);
    });
  });

  //GET Area to options
  useQuery(['GET_AREA'], async () => {
    await areaService.getList({ offset: 0, limit: 100 }).then((res: any) => {
      const options = res?.districts?.map((currency: any) => ({
        value: currency.id,
        label: currency.name?.[i18n?.language]
      }));
      setAreaOption(options);
    });
  });

  //GET Region to options
  useQuery(['GET_REGION'], async () => {
    await regionService.getList({ offset: 0, limit: 100 }).then((res: any) => {
      const options = res?.regions?.map((region: any) => ({
        value: region.id,
        label: region.name?.[i18n?.language]
      }));
      setRegionOption(options);
    });
  });

  //Fetch Users By ID
  const fetchData = () => {
    if (selectedUsersId === '') return setLoading(false);

    usersService
      .getById(selectedUsersId)
      .then((res: any) => {
        const computed = {
          date_birth: res?.data?.birth_date,
          first_name: res?.data?.first_name,
          gender: genderOption.find(
            option => option.value === res?.data?.gender
          ),
          second_name: res?.data?.last_name,
          phone: res?.data?.phone_number,
          patronymic: res?.data?.surname,
          role: { label: res?.data?.role?.name, value: res?.data?.role?.id },
          country_id: {
            label: res?.data?.country?.name?.[i18n?.language],
            value: res?.data?.country?.id
          },
          region: {
            label: res?.data?.region?.name?.[i18n?.language],
            value: res?.data?.region?.id
          },
          area: {
            label: res?.data?.district?.name?.[i18n?.language],
            value: res?.data?.district?.id
          },
          holding: {
            label: res?.data?.legal_entity?.name,
            value: res?.data?.legal_entity?.id
          },
          photo: process.env.REACT_APP_CDN_URL + res.photo
        };
        reset(computed);
      })
      .finally(() => setLoading(false));
  };

  const onSubmit: SubmitHandler<FieldValues> = data => {
    if (selectedUsersId !== '') return update(data);
    createData(data);
  };

  const createData = (data: any) => {
    setLoading(true);

    const createData = {
      birth_date: Date.parse(data?.date_birth),
      first_name: data?.first_name,
      gender: data?.gender.value,
      is_master: false,
      last_name: data?.second_name,
      law_subject_type: 'LEGAL',
      password: data?.password,
      phone_number: data?.phone,
      surname: data?.patronymic,
      role_id: data?.role.value,
      country_id: data?.country_id?.value,
      region_id: data?.region?.value,
      district_id: data?.area?.value,
      legal_entity_id: data?.holding?.value,
      user_status_id: data?.satus?.value,
      photo: data?.photo ? data.photo.split('/').pop() : ''
    };

    usersService
      .create(createData)
      .then((res: any) => {
        if (res) {
          setOpenUsers(false);
          setLoading(false);
          dispatch(
            showAlert({
              title: `${t('users')} ${t('successfully_added')}`,
              type: 'success'
            })
          );
          refetch();
        }
      })
      .catch((error: any) => {
        if (error.status === 409) {
          console.log('error409', error);
          setLoading(false);
        }
      });
  };

  const update = (data: any) => {
    setLoading(true);

    const updateData = {
      birth_date: Date.parse(data?.date_birth),
      first_name: data?.first_name,
      gender: data?.gender.value,
      is_master: false,
      last_name: data?.second_name,
      law_subject_type: 'LEGAL',
      password: data?.password,
      phone_number: data?.phone,
      surname: data?.patronymic,
      role_id: data?.role.value,
      id: selectedUsersId,
      country_id: data?.country_id?.value,
      region_id: data?.region?.value,
      district_id: data?.area?.value,
      legal_entity_id: data?.holding?.value,
      user_status_id: data?.satus?.value,
      photo: data?.photo ? data.photo.split('/').pop() : ''
    };

    console.log('updateData', updateData);
    console.log('data', data);

    usersService.update(updateData).finally(() => {
      setLoading(false);
      setOpenUsers(false);
      refetch();
    });
  };

  useEffect(() => {
    if (selectedUsersId !== '') {
      fetchData();
    }
  }, [selectedUsersId]);

  return (
    <div>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="bg-white rounded"
      >
        <Row className="mb-2">
          <Col>
            <Form.Label>{t('holding')}</Form.Label>
            <div className="react-select-container">
              <Controller
                name="holding"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    placeholder={t('select')}
                    options={legalEntityOption ?? []}
                    styles={{
                      control: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      singleValue: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      menuPortal: base => ({
                        ...base,
                        fontSize: '12.8px'
                      })
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <Form.Label>{t('user_types')}</Form.Label>
            <div className="react-select-container">
              <Controller
                name="role"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    placeholder={t('select')}
                    options={roleOption ?? []}
                    styles={{
                      control: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      singleValue: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      menuPortal: base => ({
                        ...base,
                        fontSize: '12.8px'
                      })
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Group className="md-4">
              <Form.Label>{t('last_name')}</Form.Label>
              <Form.Control
                {...register('second_name')}
                type="text"
                placeholder={t('last_name')}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Group className="md-4">
              <Form.Label>{t('name')}</Form.Label>
              <Form.Control
                {...register('first_name')}
                type="text"
                placeholder={t('name')}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Group className="md-4">
              <Form.Label>{t('surname')}</Form.Label>
              <Form.Control
                {...register('patronymic')}
                type="text"
                placeholder={t('surname')}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Group>
              <Form.Label>{t('phone_number')}</Form.Label>
              <Controller
                name="phone"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <PhoneNumberInput field={field} unicode={unicode} />
                )}
              />
            </Form.Group>
          </Col>
        </Row>

        {!selectedUsersId && (
          <Row className="mb-2">
            <Col>
              <Form.Group className="md-4">
                <Form.Label>{t('use_password')}</Form.Label>
                <Form.Control
                  {...register('password')}
                  type="password"
                  placeholder={t('use_password')}
                />
              </Form.Group>
            </Col>
          </Row>
        )}

        {/* <Row className="mb-2">
          <Col>
            <Form.Group className="md-4">
              <Form.Label>Повторите пароль</Form.Label>
              <Form.Control
                {...register('reset_password')}
                type="password"
                placeholder="reset password"
              />
            </Form.Group>
          </Col>
        </Row> */}

        <Row className="mb-2">
          <Col>
            <Form.Label>{t('country')}</Form.Label>
            <div className="react-select-container">
              <Controller
                name="country_id"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    placeholder={t('select')}
                    options={countryOption}
                    styles={{
                      control: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      singleValue: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      menuPortal: base => ({
                        ...base,
                        fontSize: '12.8px'
                      })
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Label>{t('region')}</Form.Label>
            <div className="react-select-container">
              <Controller
                name="region"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    placeholder={t('select')}
                    options={regionOption}
                    styles={{
                      control: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      singleValue: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      menuPortal: base => ({
                        ...base,
                        fontSize: '12.8px'
                      })
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Label>{t('area')}</Form.Label>
            <div className="react-select-container">
              <Controller
                name="area"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    placeholder={t('select')}
                    options={areaOption}
                    styles={{
                      control: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      singleValue: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      menuPortal: base => ({
                        ...base,
                        fontSize: '12.8px'
                      })
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Label>{t('gender')}</Form.Label>
            <div className="react-select-container">
              <Controller
                name="gender"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    placeholder={t('select')}
                    options={genderOption}
                    styles={{
                      control: base => ({
                        ...base,
                        fontSize: '12.8px' // Set the font size for the control
                      }),
                      singleValue: base => ({
                        ...base,
                        fontSize: '12.8px' // Set the font size for the selected value
                      }),
                      menuPortal: base => ({
                        ...base,
                        fontSize: '12.8px' // Set the font size for the menu portal (optional)
                      })
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Label>{t('date_birth')}</Form.Label>
            <div className="flatpickr-input-container">
              <Controller
                name="date_birth"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Flatpickr
                    className={classNames('form-control', 'ps-6')}
                    options={{
                      nextArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>`,
                      prevArrow: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>`,
                      locale: {
                        firstDayOfWeek: 0
                      },
                      monthSelectorType: 'static',
                      onDayCreate: (...[, , , dayElem]) => {
                        if (
                          dayElem.dateObj.getDay() === 5 ||
                          dayElem.dateObj.getDay() === 6
                        ) {
                          dayElem.className += ' weekend-days';
                        }
                      },
                      dateFormat: 'd-m-Y',
                      enableTime: false,
                      disableMobile: true
                    }}
                    placeholder={t('date_birth')}
                    {...field}
                  />
                )}
              />
              <UilCalendarAlt className="flatpickr-icon text-700" size={16} />
            </div>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Label>{t('status')}</Form.Label>
            <div className="react-select-container">
              <Controller
                name="status"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    placeholder={t('select')}
                    options={userStatusOption}
                    styles={{
                      control: base => ({
                        ...base,
                        fontSize: '12.8px' // Set the font size for the control
                      }),
                      singleValue: base => ({
                        ...base,
                        fontSize: '12.8px' // Set the font size for the selected value
                      }),
                      menuPortal: base => ({
                        ...base,
                        fontSize: '12.8px' // Set the font size for the menu portal (optional)
                      })
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Label>{t('photo')}</Form.Label>
            <Controller
              name="photo"
              control={control}
              defaultValue=""
              render={({ field }) => <ImageUpload {...field} />}
            />
          </Col>
        </Row>

        <Button
          className="w-100"
          variant="primary"
          type="submit"
          loading={loading}
        >
          {t('add')}
        </Button>
      </Form>
    </div>
  );
};

export default CreateUsersTable;
