/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ColumnDef } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import usersService from 'services/users';
import UsersTable from './UsersTable';
import CreateUsersTable from './CreateUsersTable';
import Lightbox from 'components/base/LightBox';
import useLightbox from 'hooks/useLightbox';

export const clientsBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'users',
    active: true
  }
];

export type ClientsType = {
  phone: string;
  legal_name: string;
  access_type: string;
  second_name: string;
  first_name: string;
  patronymic: string;
  country: string;
  region: string;
  area: string;
  gender: string;
  date_birth: string;
  status: string;
  guid: string;
  photo: string;
};

const Users = () => {
  const { t, i18n } = useTranslation();

  const [openUsers, setOpenUsers] = useState(false);
  const [selectedUsersId, setSelectedUsersId] = useState('');

  //Get USERS
  const { data, refetch } = useQuery(['GET_USERS'], () => {
    const res = usersService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.data?.users);
    return res;
  });

  const clientsData: ClientsType[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          phone: items?.phone_number,
          guid: items?.id,
          second_name: items?.first_name,
          first_name: items?.last_name,
          patronymic: items?.surname,
          /* prettier-ignore */
          date_birth: new Date(items?.birth_date)
            .toLocaleString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit'
            })
            .replace(/\//g, '-')
            .replace(',', ''),
          gender: items?.gender === 'MALE' ? t('male') : t('female'),
          role: items?.role?.name,
          country_id: items?.country?.name?.[i18n?.language],
          region: items?.region?.name?.[i18n?.language],
          area: items?.district?.name?.[i18n?.language],
          status: items?.user_staus?.name?.[i18n?.language],
          photo: items?.photo
            ? `${process.env.REACT_APP_CDN_URL}${items.photo}`
            : ''
        };
      }) ?? []
    );
  }, [data, t, i18n?.language]);

  const clientsTableColumns: ColumnDef<ClientsType>[] = [
    {
      accessorKey: 'phone',
      header: t('phone_number'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'role',
      header: t('user_types'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'second_name',
      header: t('last_name'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'first_name',
      header: t('name'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'patronymic',
      header: t('surname'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'country_id',
      header: t('country'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'region',
      header: t('region'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'area',
      header: t('area'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'gender',
      header: t('gender'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'date_birth',
      header: t('date_birth'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'photo',
      header: t('photo'),
      cell: rowData => {
        const [attachments] = useState([rowData?.row?.original.photo]);
        const { lightboxProps, openLightbox } = useLightbox(attachments);

        return (
          <>
            {rowData?.row?.original.photo && (
              <div>
                <Lightbox {...lightboxProps} />

                {attachments.map((img, index) => (
                  <img
                    key={img}
                    style={{
                      width: '35px',
                      height: '35px',
                      border: '1px solid #8200BF',
                      borderRadius: '100%',
                      cursor: 'pointer'
                    }}
                    src={img}
                    alt=""
                    onClick={() => openLightbox(index + 1)}
                  />
                ))}
              </div>
            )}
          </>
        );
      },

      meta: {
        cellProps: { className: 'text-900 text-center' }
      }
    },

    {
      accessorKey: 'status',
      header: t('status'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      id: 'action',
      cell: rowData => {
        return (
          <div className="d-flex justify-content-end gap-3">
            <Link to={`#`}>
              <FeatherIcon
                icon="edit-2"
                className="cursor-pointer"
                size={20}
                onClick={() => {
                  setSelectedUsersId(rowData?.row?.original?.guid);
                  setOpenUsers(true);
                }}
              />
            </Link>
          </div>
        );
      },
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: clientsData,
    columns: clientsTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div>
      <PageBreadcrumb items={clientsBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-5">{t('users')}</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="d-flex justify-content-between">
              <Col xs="auto">
                <SearchBox
                  placeholder={t('search')}
                  onChange={handleSearchInputChange}
                />
              </Col>

              <Col xs="auto">
                <Button
                  className="mt-3"
                  variant="primary"
                  onClick={() => setOpenUsers(true)}
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  {t('add')}
                </Button>
              </Col>
            </Row>
          </div>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <UsersTable />
          </div>

          <Modal
            show={openUsers}
            onHide={() => {
              setOpenUsers(false);
              setSelectedUsersId('');
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {t('new')}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CreateUsersTable
                setOpenUsers={setOpenUsers}
                selectedUsersId={selectedUsersId}
                refetch={refetch}
              />
            </Modal.Body>
          </Modal>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default Users;
