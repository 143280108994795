/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { useTranslation } from 'react-i18next';

import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import Button from 'components/base/Button';
import CreateNomVariation from './CreateNomVariation';
import genBarcodeService from 'services/generate';

interface VariationInfoProps {
  setOpenVariation: (show: boolean) => void;
  variationFormData?: any;
  setVariationFormData?: any;
}

export type VariationType = {
  variation: string;
  var_value: string;
  variation_id: string;
};

interface ProductVariation {
  name: string;
  values: { value: string }[];
}

const VariationInfo = ({
  setOpenVariation,
  variationFormData,
  setVariationFormData
}: VariationInfoProps) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState<VariationType[]>([]);

  const handleRemoveItem = (indexToRemove: number) => {
    setFormData(formData.filter((_, index) => index !== indexToRemove));
  };

  const createVariationTableColumns: ColumnDef<VariationType>[] = [
    {
      accessorKey: 'variation',
      header: t('variation'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'var_value',
      header: t('values'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      id: 'action',
      cell: rowData => (
        <div>
          <Link to={`#`}>
            <FeatherIcon
              icon="trash-2"
              className="cursor-pointer text-danger"
              size={20}
              onClick={() => handleRemoveItem(+rowData?.row?.id)}
            />
          </Link>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '7%' } }
      }
    }
  ];

  const table = useAdvanceTable({
    data: formData,
    columns: createVariationTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleGenerateVariation = () => {
    const productVariations: ProductVariation[] = formData.reduce(
      (acc: any, curr: any) => {
        const existingVariation = acc.find(
          (v: any) => v.name === curr.variation
        );
        const newValue = { value: curr.var_value };
        if (existingVariation) {
          existingVariation.values.push(newValue);
        } else {
          acc.push({ name: curr.variation, values: [newValue] });
        }
        return acc;
      },
      []
    );

    const cartesian = (...a: any) =>
      a.reduce((a: any, b: any) =>
        a.flatMap((d: any) => {
          const arrD = Array.isArray(d) ? d : [d];
          return b.flatMap((e: any) => {
            const arrE = Array.isArray(e) ? e : [e];
            return arrD
              .map((itemD: any) =>
                arrE.map((itemE: any) => [].concat(itemD, itemE))
              )
              .reduce((acc: any, val: any) => acc.concat(val), []);
          });
        })
      );
    const productValues = productVariations.map(({ values }) => values);

    const permutations = cartesian(...productValues).map(async (e: any) => {
      const resData = await genBarcodeService
        .getGenBarcode({
          offset: 0,
          limit: 100
        })
        .then((res: any) => res);

      const barcode = resData?.barcode;

      return {
        product:
          e.length > 0
            ? e?.map(({ value }: { value: any }) => value).join(' | ')
            : e?.value,
        var_barcode: barcode,
        vendor_code: '',
        party_number: '',
        code: '',
        var_data: formData
      };
    });

    Promise.all(permutations).then(permutationResults => {
      setVariationFormData([...variationFormData, ...permutationResults]);
      setOpenVariation(false);
    });
  };

  return (
    <div>
      <CreateNomVariation setFormData={setFormData} formData={formData} />

      <AdvanceTableProvider {...table}>
        <div className="px-4 px-lg-6 border-top border-bottom border-300">
          <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
        </div>
      </AdvanceTableProvider>

      <div className="d-flex justify-content-end">
        <Button
          className="mt-4"
          variant="primary"
          type="button"
          onClick={handleGenerateVariation}
        >
          {t('generate')} {t('product')}
        </Button>
      </div>
    </div>
  );
};

export default VariationInfo;
