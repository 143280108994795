/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';

interface UpdataNomVariationTableProps {
  setOpenUpdateVariataion: (show: boolean) => void;
  variationFormData?: any;
  selectedUpdateVariationId?: any;
  setVariationFormData?: any;
}

const UpdataNomVariationTable = ({
  setOpenUpdateVariataion,
  variationFormData,
  selectedUpdateVariationId,
  setVariationFormData
}: UpdataNomVariationTableProps) => {
  const { t } = useTranslation();

  const { register, reset, getValues } = useForm();

  //Get by id
  const fetchData = useMemo(() => {
    return () => {
      if (selectedUpdateVariationId === '') return console.log('NO id');

      const computed = variationFormData?.map((el: any) => {
        return {
          variation: el?.product,
          barcode: el?.var_barcode,
          vendor_code: el?.vendor_code,
          party_number: el?.party_number,
          code: el?.code
        };
      });

      reset(computed[selectedUpdateVariationId]);
    };
  }, [selectedUpdateVariationId]);

  // console.log('variationFormData====>', variationFormData);

  const onSubmit = () => {
    const data = getValues();

    if (selectedUpdateVariationId !== '') {
      const updatedFormData = [...variationFormData];
      updatedFormData[selectedUpdateVariationId] = {
        // ...updatedFormData[selectedUpdateVariationId],
        var_data: updatedFormData[selectedUpdateVariationId].var_data,
        nomenclature_id: updatedFormData[selectedUpdateVariationId]
          .nomenclature_id
          ? updatedFormData[selectedUpdateVariationId].nomenclature_id
          : undefined,
        product: data?.variation,
        var_barcode: data?.barcode,
        vendor_code: data.vendor_code,
        party_number: data.party_number,
        code: data.code
      };

      setVariationFormData([...updatedFormData]);
      setOpenUpdateVariataion(false);
    }
  };

  useEffect(() => {
    if (selectedUpdateVariationId !== '') {
      fetchData();
    }
  }, [selectedUpdateVariationId]);

  return (
    <div>
      <Form noValidate>
        <Row className="mb-2">
          <Col>
            <Form.Group className="md-4">
              <Form.Label>{t('variation')}</Form.Label>
              <Form.Control
                {...register('variation')}
                type="text"
                placeholder={t('variation')}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Group className="md-4">
              <Form.Label>{t('barcode')}</Form.Label>
              <Form.Control
                {...register('barcode')}
                type="text"
                placeholder={t('barcode')}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Group className="md-4">
              <Form.Label>{t('articles')}</Form.Label>
              <Form.Control
                {...register('vendor_code')}
                type="text"
                placeholder={t('articles')}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Group className="md-4">
              <Form.Label>{t('party_number')}</Form.Label>
              <Form.Control
                {...register('party_number')}
                type="text"
                placeholder={t('party_number')}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-2">
          <Col>
            <Form.Group className="md-4">
              <Form.Label>{t('code_ikpu')}</Form.Label>
              <Form.Control
                {...register('code')}
                type="text"
                placeholder={t('code_ikpu')}
              />
            </Form.Group>
          </Col>
        </Row>

        <Button className="w-100" variant="primary" onClick={onSubmit}>
          {t('save')}
        </Button>
      </Form>
    </div>
  );
};

export default UpdataNomVariationTable;
