import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const categoryBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'directories',
    url: '/'
  },
  {
    label: 'category',
    active: true
  }
];

export const subFirtsBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'directories',
    url: '/'
  },
  {
    label: 'category',
    url: '/directories/category'
  },
  {
    label: 'Подкатегория 1',
    active: true
  }
];

export const subSecondBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Справочники',
    url: '/'
  },
  {
    label: 'Категория',
    url: '/directories/category'
  },
  {
    label: 'Подкатегория 1',
    url: '/directories/category/sub-category'
  },
  {
    label: 'Подкатегория 2',
    active: true
  }
];

export const subThirdBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Справочники',
    url: '/'
  },
  {
    label: 'Категория',
    url: '/directories/category'
  },
  {
    label: 'Подкатегория 1',
    url: '/directories/category/sub-category'
  },
  {
    label: 'Подкатегория 2',
    url: '/directories/category/sub-category/second-sub-category'
  },
  {
    label: 'Подкатегория 3',
    active: true
  }
];

export type CategoryType = {
  activity: string;
  parents_category: string;
  name: string;
  text: string;
  status: string;
  guid: string;
};
