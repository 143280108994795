/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useMemo } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import Button from 'components/base/Button';
import useAdvanceTable from 'hooks/useAdvanceTable';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import SearchBox from 'components/common/SearchBox';
import versioinService from 'services/version';
import CreateVersion from './CreateVersion';

export const desktopVersionBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'directories',
    url: '/'
  },
  {
    label: 'desktop_version',
    active: true
  }
];

export type desktopVersionType = {
  name: string;
  lts: string;
  mandatory: string;
  file: string;
  guid: string;
};

const DesktopVersion = () => {
  const { t } = useTranslation();

  const [openDesktopVersion, setOpenDesktopVersion] = useState(false);
  const [selectedVersionId, setSelectedVersionId] = useState('');

  //Get Version
  const { data, refetch } = useQuery(['GET_VERSION'], () => {
    const res = versioinService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.Versions);
    return res;
  });

  const versionData: desktopVersionType[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          name: items?.name,
          lts: items?.is_lts === true ? t('yes') : t('no'),
          mandatory: items?.is_mandatory === true ? t('yes') : t('no'),
          file: items?.file,
          guid: items?.id
        };
      }) ?? []
    );
  }, [data, t]);

  const handleDelete = (id: string) => {
    versioinService.deleteVersion(id).finally(() => {
      refetch();
      setSelectedVersionId('');
    });
  };

  const versionTableColumns: ColumnDef<desktopVersionType>[] = [
    {
      id: 'action',
      cell: rowData => (
        <div className="d-flex justify-content-center gap-2">
          <Button
            className="p-1"
            variant="link"
            onClick={() => {
              setOpenDesktopVersion(true);
              setSelectedVersionId(rowData?.row?.original?.guid);
            }}
          >
            <FeatherIcon icon="edit-2" className="cursor-pointer" size={20} />
          </Button>
          <Button
            className="p-1"
            variant="link"
            onClick={() => handleDelete(rowData?.row?.original?.guid)}
          >
            <FeatherIcon
              icon="trash-2"
              className="cursor-pointer text-danger"
              size={20}
            />
          </Button>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '5%' } },
        cellProps: { className: 'text-center' }
      }
    },
    {
      accessorKey: 'name',
      header: t('name_version'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'lts',
      header: t('latest_version'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'mandatory',
      header: t('required'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'file',
      header: t('file'),
      cell: rowData => (
        <div>
          {rowData?.row?.original?.file && (
            <p className="p-0 d-flex gap-2 text-bold">
              <FeatherIcon icon="upload" />
              {t('file_uploaded')}
            </p>
          )}
        </div>
      ),
      meta: {
        cellProps: { className: 'text-900' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: versionData,
    columns: versionTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div>
      <PageBreadcrumb items={desktopVersionBreadcrumbItems} />

      <div className="mb-9">
        <h2 className="mb-5">{t('desktop_version')}</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex justify-content-between">
              <SearchBox
                placeholder={t('search')}
                onChange={handleSearchInputChange}
              />

              <div className="d-flex justify-content-between gap-2">
                <Button
                  variant="primary"
                  onClick={() => setOpenDesktopVersion(true)}
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  {t('add')}
                </Button>
              </div>
            </div>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
            <AdvanceTableFooter pagination />
          </div>

          <Offcanvas
            show={openDesktopVersion}
            onHide={() => {
              setOpenDesktopVersion(false);
              setSelectedVersionId('');
            }}
            placement="end"
            backdrop="static"
            keyboard={false}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title style={{ fontSize: '24px', fontWeight: '600' }}>
                {t('new')}
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <CreateVersion
                setOpenDesktopVersion={setOpenDesktopVersion}
                selectedVersionId={selectedVersionId}
                refetch={refetch}
                setSelectedVersionId={setSelectedVersionId}
              />
            </Offcanvas.Body>
          </Offcanvas>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default DesktopVersion;
