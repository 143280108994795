import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const activityBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'directories',
    url: '/'
  },
  {
    label: 'type_activity',
    active: true
  }
];

export type ActivityType = {
  name: string;
  status: string;
  guid: string;
};
