/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import ExpensesSourceTable from './ExpensesSourceTable';
import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import useAdvanceTable from 'hooks/useAdvanceTable';
import Button from 'components/base/Button';
import expensesSourceService from 'services/expensesSource';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import CreateExpensesSource from './CreateExpensesSource';

export const expensesBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'directories',
    url: '/'
  },
  {
    label: 'source_consumption',
    active: true
  }
];

export type ExpensesType = {
  name: string;
  status: string;
  guid: string;
};

const ExpensesSource = () => {
  const { t, i18n } = useTranslation();

  const [openExpenses, setOpenExpenses] = useState(false);
  const [selectedExpensesId, setSelectedExpensesId] = useState('');

  //Get Document
  const { data, refetch } = useQuery(['GET_DOCUMENT'], () => {
    const res = expensesSourceService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.expense_sources);
    return res;
  });

  const expensesData: ExpensesType[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          name: items?.name?.[i18n?.language],
          status: items?.status === true ? t('active') : t('blocked'),
          guid: items?.id
        };
      }) ?? []
    );
  }, [data, t, i18n?.language]);

  const handleDelete = (id: any) => {
    expensesSourceService.deleteExpense(id).finally(() => {
      refetch();
      setSelectedExpensesId('');
    });
  };

  const expensesTableColumns: ColumnDef<ExpensesType>[] = [
    {
      accessorKey: 'name',
      header: t('name_source_consumption'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status',
      header: t('status'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      id: 'action',
      cell: rowData => (
        <div className="d-flex justify-content-end gap-3">
          <Link to={`#`}>
            <FeatherIcon
              icon="edit-2"
              className="cursor-pointer"
              size={20}
              onClick={() => {
                setOpenExpenses(true);
                setSelectedExpensesId(rowData?.row?.original?.guid);
              }}
            />
          </Link>
          <Link to={`#`}>
            <FeatherIcon
              icon="trash-2"
              className="cursor-pointer text-danger"
              size={20}
              onClick={() => handleDelete(rowData?.row?.original?.guid)}
            />
          </Link>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const table = useAdvanceTable({
    data: expensesData,
    columns: expensesTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  return (
    <div>
      <PageBreadcrumb items={expensesBreadcrumbItems} />

      <div className="mb-9">
        <h2 className="mb-5">{t('source_consumption')}</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex justify-content-between mb-3">
              <SearchBox
                placeholder={t('search')}
                onChange={handleSearchInputChange}
              />

              <Button variant="primary" onClick={() => setOpenExpenses(true)}>
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                {t('add')}
              </Button>
            </div>
            <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
              <ExpensesSourceTable />
            </div>
          </div>

          <Modal
            show={openExpenses}
            onHide={() => {
              setOpenExpenses(false);
              setSelectedExpensesId('');
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {t('new')}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CreateExpensesSource
                setOpenExpenses={setOpenExpenses}
                selectedExpensesId={selectedExpensesId}
                refetch={refetch}
              />
            </Modal.Body>
          </Modal>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default ExpensesSource;
