/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useMemo, useState } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import useAdvanceTable from 'hooks/useAdvanceTable';
import Button from 'components/base/Button';
import errorsService from 'services/errors';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import CreateErrors from './CreateErrors';

export const errorsBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'directories',
    url: '/'
  },
  {
    label: 'reason_for_returning',
    active: true
  }
];

export type ErrosType = {
  name: string;
  status_code: string;
  guid: string;
};

const Errors = () => {
  const { t, i18n } = useTranslation();

  const [openErros, setOpenErrors] = useState(false);
  const [selectedErrorsId, setSelectedErrosId] = useState('');

  //Get Erros
  const { data, refetch } = useQuery(['GET_ERROS'], () => {
    const res = errorsService
      .getList({ offset: 0, limit: 1000 })
      .then((res: any) => res?.custom_errors);
    return res;
  });

  const errosData: ErrosType[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          name: items?.name?.[i18n?.language],
          status_code: items?.code,
          guid: items?.id
        };
      }) ?? []
    );
  }, [data, t, i18n?.language]);

  const handleDelete = (id: any) => {
    errorsService.deleteErrors(id).finally(() => {
      refetch();
      setSelectedErrosId('');
    });
  };

  const errosTableColumns: ColumnDef<ErrosType>[] = [
    {
      accessorKey: 'name',
      header: t('name_errors'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status_code',
      header: t('status_code'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      id: 'action',
      cell: rowData => (
        <div className="d-flex justify-content-end gap-3">
          <Link to={`#`}>
            <FeatherIcon
              icon="edit-2"
              className="cursor-pointer"
              size={20}
              onClick={() => {
                setOpenErrors(true);
                setSelectedErrosId(rowData?.row?.original?.guid);
              }}
            />
          </Link>
          <Link to={`#`}>
            <FeatherIcon
              icon="trash-2"
              className="cursor-pointer text-danger"
              size={20}
              onClick={() => handleDelete(rowData?.row?.original?.guid)}
            />
          </Link>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: errosData,
    columns: errosTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div>
      <PageBreadcrumb items={errorsBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-5">{t('errors')}</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="d-flex justify-content-between">
              <Col xs="auto">
                <SearchBox
                  placeholder={t('search')}
                  onChange={handleSearchInputChange}
                />
              </Col>

              <Col xs="auto">
                <Button
                  className="mt-3"
                  variant="primary"
                  onClick={() => setOpenErrors(true)}
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  {t('add')}
                </Button>
              </Col>
            </Row>
          </div>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
            <AdvanceTableFooter pagination />
          </div>

          <Modal
            show={openErros}
            onHide={() => {
              setOpenErrors(false);
              setSelectedErrosId('');
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {t('new')}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CreateErrors
                setOpenErrors={setOpenErrors}
                selectedErrorsId={selectedErrorsId}
                refetch={refetch}
              />
            </Modal.Body>
          </Modal>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default Errors;
