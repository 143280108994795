/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useMemo, useState } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { categoryBreadcrumbItems } from 'data/category';
import Button from 'components/base/Button';
import CreateCategory from './CreateCategory';
import categoryService from 'services/category';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import CategoryAdvanceTable from 'components/base/CategoryAdvanceTable';
import CreateSubCategory from './CreateSubCategory';

interface TransformedCategory {
  activity: string;
  name: string;
  text: string;
  status: string;
  guid: string;
  children: TransformedCategory[];
}

const transformData = (
  data: any[],
  t: any,
  i18n: any
): TransformedCategory[] => {
  const categoryMap = new Map<string, TransformedCategory>();

  data.forEach(item => {
    categoryMap.set(item?.id, {
      activity: item?.activity_kind?.name?.[i18n?.language],
      name: item?.name?.[i18n?.language],
      text: item?.description,
      status: item?.status ? t('active') : t('blocked'),
      guid: item?.id,
      children: []
    });
  });

  data.forEach(item => {
    if (item.parent_id) {
      const parent = categoryMap.get(item.parent_id);
      if (parent) {
        parent?.children?.push(categoryMap.get(item?.id)!);
      }
    }
  });

  const topLevelCategories: TransformedCategory[] = [];
  categoryMap.forEach((category, id) => {
    if (!data.find(item => item.id === id)?.parent_id) {
      topLevelCategories.push(category);
    }
  });

  return topLevelCategories;
};

const Category = () => {
  const { t, i18n } = useTranslation();

  const [openCategory, setOpenCategory] = useState(false);
  const [openSubCategory, setOpenSubCategory] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState('');

  // Get Category
  const { data, refetch } = useQuery('GET_CATEGORY', () =>
    categoryService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.categories)
  );

  const categoryData: TransformedCategory[] = useMemo(() => {
    return data ? transformData(data, t, i18n) : [];
  }, [data, t, i18n?.language]);

  const handleDelete = (id: string) => {
    categoryService.deleteCategory(id).finally(() => {
      refetch();
      setSelectedCategoryId('');
      setSelectedSubCategoryId('');
    });
  };

  const categoryTableColumns: ColumnDef<TransformedCategory>[] = [
    {
      accessorKey: 'name',
      header: t('name_category'),
      cell: (rowData: any) => {
        return (
          <div style={{ paddingLeft: `${rowData?.row?.depth * 20}px` }}>
            {rowData?.row?.children?.length > 0 ? (
              <span style={{ fontWeight: 'bold' }}>{rowData?.row?.name}</span>
            ) : (
              <span>{rowData?.row?.name}</span>
            )}
          </div>
        );
      },
      meta: { cellProps: { className: 'text-900' } }
    },
    {
      accessorKey: 'activity',
      header: t('type_activity'),
      meta: { cellProps: { className: 'text-900' } }
    },
    {
      accessorKey: 'text',
      header: t('description'),
      meta: { cellProps: { className: 'text-900' } }
    },
    {
      accessorKey: 'status',
      header: t('status'),
      meta: { cellProps: { className: 'text-900' } }
    },
    {
      id: 'action',
      cell: ({ row }: any) => (
        <ActionButtons
          guid={row?.guid}
          setOpenCategory={setOpenCategory}
          setSelectedCategoryId={setSelectedCategoryId}
          handleDelete={handleDelete}
          setSelectedSubCategoryId={setSelectedSubCategoryId}
          setOpenSubCategory={setOpenSubCategory}
        />
      ),
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: categoryData,
    columns: categoryTableColumns,
    pageSize: categoryData ? categoryData?.length : 50,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div>
      <PageBreadcrumb items={categoryBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-5">{t('category')}</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="d-flex justify-content-between">
              <Col xs="auto">
                <SearchBox
                  placeholder={t('search')}
                  onChange={handleSearchInputChange}
                />
              </Col>

              <Col xs="auto">
                <Button
                  className="mt-3"
                  variant="primary"
                  onClick={() => setOpenCategory(true)}
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  {t('add')}
                </Button>
              </Col>
            </Row>
          </div>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <CategoryAdvanceTable
              tableProps={{ className: 'phoenix-table fs-9' }}
              data={categoryData}
              columns={categoryTableColumns}
            />
            <AdvanceTableFooter pagination />
          </div>

          <Modal
            show={openCategory}
            onHide={() => {
              setOpenCategory(false);
              setSelectedCategoryId('');
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {t('add_new_category')}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CreateCategory
                setOpenCategory={setOpenCategory}
                selectedCategoryId={selectedCategoryId}
                refetch={refetch}
              />
            </Modal.Body>
          </Modal>

          <Modal
            show={openSubCategory}
            onHide={() => {
              setOpenSubCategory(false);
              setSelectedSubCategoryId('');
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {t('add_new_sub_category')}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CreateSubCategory
                setOpenSubCategory={setOpenSubCategory}
                selectedSubCategoryId={selectedSubCategoryId}
                refetch={refetch}
              />
            </Modal.Body>
          </Modal>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

const ActionButtons = ({
  guid,
  setOpenCategory,
  setSelectedCategoryId,
  handleDelete,
  setOpenSubCategory,
  setSelectedSubCategoryId
}: {
  guid: string;
  setOpenCategory: (state: boolean) => void;
  setSelectedCategoryId: (id: string) => void;
  handleDelete: (id: string) => void;
  setOpenSubCategory: (state: boolean) => void;
  setSelectedSubCategoryId: (id: string) => void;
}) => (
  <div className="d-flex justify-content-end gap-3">
    <Link to={`#`}>
      <FeatherIcon
        icon="plus"
        className="cursor-pointer"
        size={20}
        onClick={() => {
          setOpenSubCategory(true);
          setSelectedSubCategoryId(guid);
        }}
      />
    </Link>
    <Link to="#">
      <FeatherIcon
        icon="edit-2"
        className="cursor-pointer"
        size={20}
        onClick={() => {
          setOpenCategory(true);
          setSelectedCategoryId(guid);
        }}
      />
    </Link>
    <Link to="#">
      <FeatherIcon
        icon="trash-2"
        className="cursor-pointer text-danger"
        size={20}
        onClick={() => handleDelete(guid)}
      />
    </Link>
  </div>
);

export default Category;
