/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { Spinner } from 'react-bootstrap';
import ImageViewer from 'react-simple-image-viewer';
import { useTranslation } from 'react-i18next';

import uploadService from 'services/fileService';
import './style.scss';

interface ImageUploadProps {
  value?: string[]; // Array of image URLs
  onChange?: (images: string[]) => void;
  className?: string;
}

const MultiImageUpload = ({
  value = [],
  onChange,
  className
}: ImageUploadProps) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [currentPreviewIndex, setCurrentPreviewIndex] = useState(0);

  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

  const openImagePreview = (index: number) => {
    setCurrentPreviewIndex(index);
    setPreviewVisible(true);
  };

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const files = Array.from(e.target.files);
    setLoading(true);
    setError(null);

    const newImages: string[] = [];

    for (const file of files) {
      if (file.size > MAX_FILE_SIZE) {
        setLoading(false);
        setError('One or more files exceed the 5MB size limit');
        return;
      }

      const data = new FormData();
      data.append('file', file);

      try {
        await uploadService.uploadImage(data)?.then((res: any) => {
          console.log('filename', res);
          if (res) {
            newImages.push(process.env.REACT_APP_CDN_URL + res.filename);
          }
        });
      } catch {
        setError('Error uploading one or more images');
      }
    }

    setLoading(false);
    onChange?.([...(Array.isArray(value) ? value : []), ...newImages]); // Ensure value is an array before updating
  };

  const deleteImage = (index: number) => {
    const updatedImages = (Array.isArray(value) ? value : []).filter(
      (_, i) => i !== index
    );
    onChange?.(updatedImages);
  };

  return (
    <div className={`Gallery ${className}`}>
      {(Array.isArray(value) ? value : []).map((image, index) => (
        <div
          key={index}
          className="block"
          onClick={() => openImagePreview(index)}
        >
          <button
            className="close-btn"
            type="button"
            onClick={e => {
              e.stopPropagation();
              deleteImage(index);
            }}
          >
            <FeatherIcon icon="x-circle" />
          </button>
          <img src={image} alt={`Uploaded ${index}`} />
        </div>
      ))}

      <div
        className="add-block block"
        onClick={() => inputRef.current?.click()}
      >
        <div className="add-icon">
          {!loading ? (
            <>
              <FeatherIcon icon="plus" style={{ color: '#8200BF' }} />
              <p className="mb-0">{t('download')}</p>
            </>
          ) : (
            <Spinner animation="border" style={{ color: '#8200BF' }} />
          )}
        </div>

        <input
          type="file"
          className="hidden"
          ref={inputRef}
          multiple
          onChange={handleInputChange}
        />
      </div>

      {error && <p className="error-message">{error}</p>}

      {previewVisible && (
        <ImageViewer
          src={Array.isArray(value) ? value : []}
          currentIndex={currentPreviewIndex}
          disableScroll={true}
          closeOnClickOutside={true}
          onClose={() => setPreviewVisible(false)}
        />
      )}
    </div>
  );
};

export default MultiImageUpload;
