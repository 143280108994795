import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const productBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'directories',
    url: '/'
  },
  {
    label: 'product_type',
    active: true
  }
];

export type ProductType = {
  name: string;
  status: string;
  guid: string;
};
