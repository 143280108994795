/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const uploadService = {
  uploadImage: (data: any) => request.post('/upload/nomenclature', data),
  getByIdUploadedImage: (id: any) => request.get(`/nomenclature-photo/${id}`),
  createUploadedImage: (data: any) => request.post('/nomenclature-photo', data)
};

export default uploadService;
