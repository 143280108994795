/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import { showAlert } from 'store/alert/alert.thunk';
import languageService from 'services/language';
import countryService from 'services/country';

import ndsService from 'services/nds';

interface CreateNdsProps {
  setOpenNds: (show: boolean) => void;
  selectedNdsId?: any;
  refetch?: any;
}

const CreateNds = ({ setOpenNds, selectedNdsId, refetch }: CreateNdsProps) => {
  const { t, i18n } = useTranslation();

  const options = [
    {
      value: true,
      label: t('active')
    },
    {
      value: false,
      label: t('blocked')
    }
  ];
  const [loading, setLoading] = useState(false);
  const [countryOption, setCountryOption] = useState([]);

  const { register, handleSubmit, control, reset } = useForm();

  const dispatch: Dispatch<any> = useDispatch();

  //GET Lang
  const { data: langData } = useQuery(['GET_LANGUAGE'], () => {
    const res = languageService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.languages);
    return res;
  });

  //GET Country to options
  useQuery(['GET_COUNTRY'], async () => {
    await countryService.getList({ offset: 0, limit: 100 }).then((res: any) => {
      const options = res?.countries?.map((country: any) => ({
        value: country.id,
        label: country.name?.[i18n?.language]
      }));
      setCountryOption(options);
    });
  });

  //Get by id
  const fetchData = () => {
    if (selectedNdsId === '') return setLoading(false);

    ndsService
      .getNdsById(selectedNdsId)
      .then((res: any) => {
        const computed = {
          country: countryOption.find(
            (option: any) => option.value === res?.country_id
          ),
          nds: res?.value,
          status: options.find(option => option.value === res?.status)
        };
        reset(computed);
      })
      .finally(() => setLoading(false));
  };

  const onSubmit: SubmitHandler<FieldValues> = data => {
    if (selectedNdsId !== '') return update(data);
    createData(data);
  };

  const createData = (data: any) => {
    setLoading(true);

    const createdData = {
      value: +data?.nds,
      country_id: data?.country?.value,
      status: data?.status.value
    };

    ndsService
      .createNds(createdData)
      .then((res: any) => {
        if (res) {
          dispatch(
            showAlert({
              title: `${t('vat')} ${t('successfully_added')}`,
              type: 'success'
            })
          );
        }
      })
      .finally(() => {
        refetch();
        setOpenNds(false);
        setLoading(false);
      });
  };

  const update = (data: any) => {
    setLoading(true);

    const updateData = {
      value: +data?.nds,
      country_id: data?.country?.value,
      status: data?.status.value,
      id: selectedNdsId
    };
    ndsService.updateNds(selectedNdsId, updateData).finally(() => {
      refetch();
      setLoading(false);
      setOpenNds(false);
    });
  };

  useEffect(() => {
    if (selectedNdsId !== '') {
      fetchData();
    }
  }, [langData, countryOption, selectedNdsId]);
  return (
    <div>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="bg-white rounded"
      >
        <Row className="mb-3">
          <Col>
            <Form.Label>{t('country')}</Form.Label>
            <div className="react-select-container">
              <Controller
                name="country"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    placeholder={t('select')}
                    options={countryOption}
                    styles={{
                      control: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      singleValue: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      menuPortal: base => ({
                        ...base,
                        fontSize: '12.8px'
                      })
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Group className="md-4">
              <Form.Label>{t('meaning_vat')}</Form.Label>
              <Form.Control
                {...register(`nds`)}
                type="number"
                placeholder={t('meaning_vat')}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Label>{t('status')}</Form.Label>
            <div className="react-select-container">
              <Controller
                name="status"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    placeholder={t('select')}
                    options={options}
                    styles={{
                      control: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      singleValue: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      menuPortal: base => ({
                        ...base,
                        fontSize: '12.8px'
                      })
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>

        <Button
          className="w-100"
          variant="primary"
          type="submit"
          loading={loading}
        >
          {t('add')}
        </Button>
      </Form>
    </div>
  );
};

export default CreateNds;
