import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const countryBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'directories',
    url: '/'
  },
  {
    label: 'country',
    active: true
  }
];

export type CountryType = {
  full_name: string;
  short_name: string;
  language: string;
  code_lang: string;
  code: string;
  num_code: string;
  phone_code: string;
  phone_mask: string;
  status: string;
  guid: string;
};
