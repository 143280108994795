import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const holdingBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'holding',
    active: true
  }
];

export type HoldingType = {
  name: string;
  address: string;
  phone_number: string;
  activity: string;
  country: string;
  currency: string;
  area: string;
  region: string;
  access: string;
  entity_status: string;
  guid: string;
  logo_url: string;
};
