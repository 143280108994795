/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useState, useMemo } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import UsersTypeTable from './UsersTypeTable';
import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { usersTypeBreadcrumbItems, UsersTSType } from 'data/usersType';
import Button from 'components/base/Button';
import CreateUsersType from './CreateUsersType';
import rolesService from 'services/roles';

const UsersType = () => {
  const { t } = useTranslation();

  const [openRole, setOpenRole] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState('');

  //Get Role
  const { data, refetch } = useQuery(['GET_ROLE'], () => {
    const res = rolesService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.data?.roles);
    return res;
  });

  const roleData: UsersTSType[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          name: items?.name,
          status: items?.system_access === true ? t('active') : t('blocked'),
          guid: items?.id
        };
      }) ?? []
    );
  }, [data, t]);

  const handleDelete = (id: any) => {
    rolesService.deleteRole(id).finally(() => {
      refetch();
      setSelectedRoleId('');
    });
  };

  const usersTypeTableColumns: ColumnDef<UsersTSType>[] = [
    {
      accessorKey: 'name',
      header: t('user_type_name'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status',
      header: t('status'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      id: 'action',
      cell: rowData => (
        <div className="d-flex justify-content-end gap-3">
          <Link to={`#`}>
            <FeatherIcon
              icon="edit-2"
              className="cursor-pointer"
              size={20}
              onClick={() => {
                setOpenRole(true);
                setSelectedRoleId(rowData?.row?.original?.guid);
              }}
            />
          </Link>
          <Link to={`#`}>
            <FeatherIcon
              icon="trash-2"
              className="cursor-pointer text-danger"
              size={20}
              onClick={() => handleDelete(rowData?.row?.original?.guid)}
            />
          </Link>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: roleData,
    columns: usersTypeTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };
  return (
    <div>
      <PageBreadcrumb items={usersTypeBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-5">{t('users_type')}</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="d-flex justify-content-between">
              <Col xs="auto">
                <SearchBox
                  placeholder={t('search')}
                  onChange={handleSearchInputChange}
                />
              </Col>

              <Col xs="auto">
                <Button
                  className="mt-3"
                  variant="primary"
                  onClick={() => setOpenRole(true)}
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  {t('add')}
                </Button>
              </Col>
            </Row>
          </div>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <UsersTypeTable />
          </div>

          <Modal
            show={openRole}
            onHide={() => {
              setOpenRole(false);
              setSelectedRoleId('');
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {t('new')}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CreateUsersType
                setOpenRole={setOpenRole}
                selectedRoleId={selectedRoleId}
                refetch={refetch}
              />
            </Modal.Body>
          </Modal>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default UsersType;
