/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnDef } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { CharacterType } from '../CreateNomenclature';
import CreateCharacteristics from './CreateCharacteristics';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import './styles.scss';

interface SpecificationInfoProps {
  setOpenCharacter: (show: boolean) => void;
  openCharacter?: any;
  characterFormData?: any;
  setCharacterFormData?: any;
}

const SpecificationInfo = ({
  openCharacter,
  setOpenCharacter,
  characterFormData,
  setCharacterFormData
}: SpecificationInfoProps) => {
  const { t } = useTranslation();

  //remove charcter table items
  const handleRemoveCharacterItem = (indexToRemove: number) => {
    setCharacterFormData(
      characterFormData.filter((_: any, index: any) => index !== indexToRemove)
    );
  };
  //character column
  const charDataTableColumns: ColumnDef<CharacterType>[] = [
    {
      accessorKey: 'character_name',
      header: t('characteristic'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'values',
      header: t('values'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      id: 'action',
      cell: rowData => (
        <div>
          <Link to={`#`}>
            <FeatherIcon
              icon="trash-2"
              className="cursor-pointer text-danger"
              size={20}
              onClick={() => handleRemoveCharacterItem(+rowData?.row?.id)}
            />
          </Link>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '7%' } }
      }
    }
  ];
  //charcter table
  const tableCharacter = useAdvanceTable({
    data: characterFormData,
    columns: charDataTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  return (
    <AdvanceTableProvider {...tableCharacter}>
      <div className="mb-2 px-4 px-lg-6 border-top border-bottom border-300">
        <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
        <AdvanceTableFooter pagination />
      </div>

      <Modal
        show={openCharacter}
        onHide={() => setOpenCharacter(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-char"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('characteristic')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateCharacteristics
            setOpenCharacter={setOpenCharacter}
            setCharacterFormData={setCharacterFormData}
            characterFormData={characterFormData}
          />
        </Modal.Body>
      </Modal>
    </AdvanceTableProvider>
  );
};

export default SpecificationInfo;
