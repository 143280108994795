import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const inventoryBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'directories',
    url: '/'
  },
  {
    label: 'inventory_status',
    active: true
  }
];

export type InventoryType = {
  name: string;
  status: string;
  guid: string;
};
