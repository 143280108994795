import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const languagesBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'directories',
    url: '/'
  },
  {
    label: 'languages',
    active: true
  }
];

export type LanguagesType = {
  name: string;
  code: string;
  status: string;
  guid: string;
};
