/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import Select from 'react-select';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';

import languageService from 'services/language';
import Button from 'components/base/Button';
import { showAlert } from 'store/alert/alert.thunk';

interface CreateLanguagesProps {
  setOpenLang: (show: boolean) => void;
  selectedLangId?: any;
  refetch?: any;
}

const CreateLanguages = ({
  setOpenLang,
  selectedLangId,
  refetch
}: CreateLanguagesProps) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const options = [
    {
      value: true,
      label: t('active')
    },
    {
      value: false,
      label: t('blocked')
    }
  ];

  const { register, handleSubmit, control, reset } = useForm();

  const dispatch: Dispatch<any> = useDispatch();

  const fetchData = () => {
    if (selectedLangId === '') return setLoading(false);

    languageService
      .getLangById(selectedLangId)
      .then((res: any) => {
        const computed = {
          name: res?.name,
          code: res?.char_code,
          status: options.find(option => option.value === res?.status)
        };
        reset(computed);
      })
      .finally(() => setLoading(false));
  };

  const onSubmit: SubmitHandler<FieldValues> = data => {
    if (selectedLangId !== '') return update(data);
    createData(data);
  };

  const createData = (data: any) => {
    setLoading(true);

    const createdData = {
      name: data?.name,
      char_code: data?.code,
      status: data?.status.value
    };

    languageService
      .createLang(createdData)
      .then((res: any) => {
        if (res) {
          dispatch(
            showAlert({
              title: `${t('languages')} ${t('successfully_added')}`,
              type: 'success'
            })
          );
        }
      })
      .finally(() => {
        refetch();
        setOpenLang(false);
        setLoading(false);
      });
  };

  const update = (data: any) => {
    setLoading(true);

    const updateData = {
      name: data?.name,
      char_code: data?.code,
      status: data?.status.value,
      id: selectedLangId
    };
    languageService.updateLang(selectedLangId, updateData).finally(() => {
      refetch();
      setLoading(false);
      setOpenLang(false);
    });
  };

  useEffect(() => {
    if (selectedLangId !== '') {
      fetchData();
    }
  }, [selectedLangId]);

  return (
    <div>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="bg-white rounded"
      >
        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>{t('name_lang')}</Form.Label>
              <Form.Control
                {...register('name')}
                type="text"
                placeholder={t('name_lang')}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>{t('lang_code')}</Form.Label>
              <Form.Control
                {...register('code')}
                type="text"
                placeholder={t('lang_code')}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Label>{t('status')}</Form.Label>
            <div className="react-select-container">
              <Controller
                name="status"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    placeholder="Select status"
                    options={options}
                    styles={{
                      control: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      singleValue: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      menuPortal: base => ({
                        ...base,
                        fontSize: '12.8px'
                      })
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>

        <Button
          className="w-100"
          variant="primary"
          type="submit"
          loading={loading}
        >
          {t('add')}
        </Button>
      </Form>
    </div>
  );
};

export default CreateLanguages;
