/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useMemo } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import PurchaseTable from './PurchaseTable';
import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { purchaseBreadcrumbItems, PurchaseType } from 'data/purchase';
import Button from 'components/base/Button';
import CreatePurchase from './CreatePurchase';
import invoiceTypeService from 'services/invoiceType';

const Purchase = () => {
  const { t, i18n } = useTranslation();

  const [openPurchase, setOpenPurchase] = useState(false);
  const [selectedPurchaseId, setSelectedPurchaseId] = useState('');

  //Get Invoice type
  const { data, refetch } = useQuery(['GET_INVOICE_TYPE'], () => {
    const res = invoiceTypeService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.invoice_types);
    return res;
  });

  const purchaseData: PurchaseType[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          name: items?.name?.[i18n?.language],
          status: items?.status === true ? t('active') : t('blocked'),
          guid: items?.id
        };
      }) ?? []
    );
  }, [data, t, i18n?.language]);

  const handleDelete = (id: any) => {
    invoiceTypeService.deleteInvoiceType(id).finally(() => {
      refetch();
      setSelectedPurchaseId('');
    });
  };

  const purchaseTableColumns: ColumnDef<PurchaseType>[] = [
    {
      accessorKey: 'name',
      header: t('name_purchase_type'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'status',
      header: t('status'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      id: 'action',
      cell: rowData => (
        <div className="d-flex justify-content-end gap-3">
          <Link to={`#`}>
            <FeatherIcon
              icon="edit-2"
              className="cursor-pointer"
              size={16}
              onClick={() => {
                setOpenPurchase(true);
                setSelectedPurchaseId(rowData?.row?.original?.guid);
              }}
            />
          </Link>
          <Link to={`#`}>
            <FeatherIcon
              icon="trash-2"
              className="cursor-pointer text-danger"
              size={20}
              onClick={() => handleDelete(rowData?.row?.original?.guid)}
            />
          </Link>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-end' }
      }
    }
  ];
  const table = useAdvanceTable({
    data: purchaseData,
    columns: purchaseTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };
  return (
    <div>
      <PageBreadcrumb items={purchaseBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-5">{t('purchase_type')}</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="d-flex justify-content-between">
              <Col xs="auto">
                <SearchBox
                  placeholder={t('search')}
                  onChange={handleSearchInputChange}
                />
              </Col>

              <Col xs="auto">
                <Button
                  className="mt-3"
                  variant="primary"
                  onClick={() => setOpenPurchase(true)}
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  {t('add')}
                </Button>
              </Col>
            </Row>
          </div>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <PurchaseTable />
          </div>

          <Modal
            show={openPurchase}
            onHide={() => {
              setOpenPurchase(false);
              setSelectedPurchaseId('');
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {t('new')}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CreatePurchase
                setOpenPurchase={setOpenPurchase}
                selectedPurchaseId={selectedPurchaseId}
                refetch={refetch}
              />
            </Modal.Body>
          </Modal>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default Purchase;
