import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const timezoneBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'directories',
    url: '/'
  },
  {
    label: 'time_zone',
    active: true
  }
];

export type TimeZoneType = {
  name: string;
  country: string;
  main: string;
  status: string;
  guid: string;
};
