import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const procedureBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'directories',
    url: '/'
  },
  {
    label: 'payment_procedure',
    active: true
  }
];

export type ProcedureType = {
  name: string;
  status: string;
  guid: string;
};
