/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import specificationService from 'services/specification';

interface CreateCharacteristicsProps {
  setOpenCharacter: (show: boolean) => void;
  setCharacterFormData?: any;
  characterFormData?: any;
}

const CreateCharacteristics = ({
  setOpenCharacter,
  setCharacterFormData,
  characterFormData
}: CreateCharacteristicsProps) => {
  const { t, i18n } = useTranslation();

  const { register, control, getValues } = useForm();

  const [specificationOption, setSpecificationOption] = useState([]);

  //GET Specification to options
  useQuery(['GET_SPECIFICATION'], async () => {
    await specificationService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.specifications?.map((option: any) => ({
          value: option.id,
          label: option.name?.[i18n?.language]
        }));
        setSpecificationOption(options);
      });
  });

  const onSubmit = () => {
    const data = getValues();
    const formDataItem = {
      character_name: data?.name?.label,
      values: data?.values,
      specification_id: data?.name?.value
    };
    setCharacterFormData([...characterFormData, formDataItem]);
    setOpenCharacter(false);
  };
  return (
    <div>
      <Form noValidate>
        <Row className="mb-2">
          <Col>
            <Form.Label>{t('characteristic')}</Form.Label>
            <div className="react-select-container">
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    placeholder={t('select')}
                    options={specificationOption}
                    styles={{
                      control: base => ({
                        ...base,
                        fontSize: '12.8px' // Set the font size for the control
                      }),
                      singleValue: base => ({
                        ...base,
                        fontSize: '12.8px' // Set the font size for the selected value
                      }),
                      menuPortal: base => ({
                        ...base,
                        fontSize: '12.8px' // Set the font size for the menu portal (optional)
                      })
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col>
            <Form.Group className="md-4">
              <Form.Label>{t('values')}</Form.Label>
              <Form.Control
                {...register('values')}
                type="text"
                placeholder={t('values')}
              />
            </Form.Group>
          </Col>
        </Row>

        <Button
          className="w-100"
          variant="primary"
          type="button"
          onClick={onSubmit}
        >
          {t('save')}
        </Button>
      </Form>
    </div>
  );
};

export default CreateCharacteristics;
