/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useMemo } from 'react';
import { Col, Row, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import FeatherIcon from 'feather-icons-react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import TypeOfPaymentTable from './PaymentTable';
import SearchBox from 'components/common/SearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { paymentBreadcrumbItems } from 'data/payment';
import Button from 'components/base/Button';
import CreateTypeOfPayment from './CreatePayment';
import paymentMethodService from 'services/paymentMethod';
import { PaymentType } from 'data/payment';
import Lightbox from 'components/base/LightBox';
import useLightbox from 'hooks/useLightbox';

const TypeOfPayment = () => {
  const { t, i18n } = useTranslation();
  const [openPayment, setOpenPayment] = useState(false);
  const [selectedPaymentId, setSelectedPaymentId] = useState('');

  //Get PAYMENT_METHOD
  const { data, refetch } = useQuery(['GET_PAYMENT_METHOD'], () => {
    const res = paymentMethodService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.payment_methods);
    return res;
  });

  const paymentMethodData: PaymentType[] = useMemo(() => {
    return (
      data?.map((items: any) => {
        return {
          name: items?.name?.[i18n?.language],
          count: items?.paymentType?.name?.[i18n?.language],
          country: items?.country.name?.[i18n?.language],
          units: items?.currency?.name?.[i18n?.language],
          show: items?.use_on_pos_sale === true ? t('yes') : t('no'),
          status: items?.status === true ? t('active') : t('blocked'),
          photo: items?.photo
            ? `${process.env.REACT_APP_CDN_URL}${items.photo}`
            : '',
          guid: items?.id
        };
      }) ?? []
    );
  }, [data, t, i18n?.language]);

  const handleDelete = (id: any) => {
    paymentMethodService.deletePaymentMethod(id).finally(() => {
      refetch();
      setSelectedPaymentId('');
    });
  };

  const paymentTableColumns: ColumnDef<PaymentType>[] = [
    {
      accessorKey: 'name',
      header: t('name_payment'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'count',
      header: t('calculation_type'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'country',
      header: t('country'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'units',
      header: t('currency'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'show',
      header: t('show_in_pos'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'photo',
      header: t('photo'),
      cell: rowData => {
        const [attachments] = useState([rowData?.row?.original.photo]);
        const { lightboxProps, openLightbox } = useLightbox(attachments);

        return (
          <>
            {rowData?.row?.original.photo && (
              <div>
                <Lightbox {...lightboxProps} />

                {attachments.map((img, index) => (
                  <img
                    key={img}
                    style={{
                      width: '35px',
                      height: '35px',
                      border: '1px solid #8200BF',
                      borderRadius: '100%',
                      cursor: 'pointer'
                    }}
                    src={img}
                    alt=""
                    onClick={() => openLightbox(index + 1)}
                  />
                ))}
              </div>
            )}
          </>
        );
      },

      meta: {
        cellProps: { className: 'text-900 text-center' }
      }
    },

    {
      accessorKey: 'status',
      header: t('status'),
      meta: {
        cellProps: { className: 'text-900' }
      }
    },

    {
      id: 'action',
      cell: rowData => (
        <div className="d-flex justify-content-end gap-3">
          <Link to={`#`}>
            <FeatherIcon
              icon="edit-2"
              className="cursor-pointer"
              size={20}
              onClick={() => {
                setOpenPayment(true);
                setSelectedPaymentId(rowData?.row?.original?.guid);
              }}
            />
          </Link>
          <Link to={`#`}>
            <FeatherIcon
              icon="trash-2"
              className="cursor-pointer text-danger"
              size={20}
              onClick={() => handleDelete(rowData?.row?.original?.guid)}
            />
          </Link>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: paymentMethodData,
    columns: paymentTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };
  return (
    <div>
      <PageBreadcrumb items={paymentBreadcrumbItems} />
      <div className="mb-9">
        <h2 className="mb-5">{t('payment_type')}</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="d-flex justify-content-between">
              <Col xs="auto">
                <SearchBox
                  placeholder={t('search')}
                  onChange={handleSearchInputChange}
                />
              </Col>

              <Col xs="auto">
                <Button
                  className="mt-3"
                  variant="primary"
                  onClick={() => setOpenPayment(true)}
                >
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  {t('add')}
                </Button>
              </Col>
            </Row>
          </div>
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-300 position-relative top-1">
            <TypeOfPaymentTable />
          </div>

          <Modal
            show={openPayment}
            onHide={() => {
              setOpenPayment(false);
              setSelectedPaymentId('');
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                {t('new')}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CreateTypeOfPayment
                setOpenPayment={setOpenPayment}
                selectedPaymentId={selectedPaymentId}
                refetch={refetch}
              />
            </Modal.Body>
          </Modal>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default TypeOfPayment;
