/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col } from 'react-bootstrap';
import { useForm, FieldValues, SubmitHandler } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import languageService from 'services/language';
import { showAlert } from 'store/alert/alert.thunk';
import errorsService from 'services/errors';

interface CreateErrorsProps {
  setOpenErrors: (show: boolean) => void;
  selectedErrorsId?: any;
  refetch?: any;
}

const CreateErrors = ({
  setOpenErrors,
  selectedErrorsId,
  refetch
}: CreateErrorsProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, reset } = useForm();

  const dispatch: Dispatch<any> = useDispatch();

  //GET Lang
  const { data: langData } = useQuery(['GET_LANGUAGE'], () => {
    const res = languageService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.languages);
    return res;
  });

  //Get by id
  const fetchData = () => {
    if (selectedErrorsId === '') return setLoading(false);

    errorsService
      .getErrorsById(selectedErrorsId)
      .then((res: any) => {
        const nameObj: any = {};
        Object.keys(res.name).forEach(lang => {
          nameObj[`name_${lang}`] = res.name[lang];
        });

        const computed = {
          ...nameObj,
          status_code: res?.code
        };
        reset(computed);
      })
      .finally(() => setLoading(false));
  };

  const onSubmit: SubmitHandler<FieldValues> = data => {
    if (selectedErrorsId !== '') return update(data);
    createData(data);
  };

  const createData = (data: any) => {
    setLoading(true);

    const dynamicKeys = Object.keys(data).filter(key =>
      key.startsWith('name_')
    );
    const nameObj: any = {};

    dynamicKeys.forEach(key => {
      const lang = key.split('_')[1];
      nameObj[lang] = data[key];
    });

    const createdData = {
      name: nameObj,
      code: +data?.status_code
    };

    errorsService
      .createErrors(createdData)
      .then((res: any) => {
        if (res) {
          dispatch(
            showAlert({
              title: `${t('errors')} ${t('successfully_added')}`,
              type: 'success'
            })
          );
        }
      })
      .finally(() => {
        refetch();
        setOpenErrors(false);
        setLoading(false);
      });
  };

  const update = (data: any) => {
    setLoading(true);

    const dynamicKeys = Object.keys(data).filter(key =>
      key.startsWith('name_')
    );
    const nameObj: any = {};

    dynamicKeys.forEach(key => {
      const lang = key.split('_')[1];
      nameObj[lang] = data[key];
    });

    const updateData = {
      name: nameObj,
      code: +data?.status_code,
      id: selectedErrorsId
    };
    errorsService.updateErrors(selectedErrorsId, updateData).finally(() => {
      refetch();
      setLoading(false);
      setOpenErrors(false);
    });
  };

  useEffect(() => {
    if (selectedErrorsId !== '') {
      fetchData();
    }
  }, [langData, selectedErrorsId]);

  return (
    <div>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="bg-white rounded"
      >
        {langData &&
          langData.map((item: any) => (
            <Row key={item.id} className="mb-3">
              <Col>
                <Form.Group className="md-4">
                  <Form.Label>
                    {t('name_errors')} ( {item.char_code} )
                  </Form.Label>
                  <Form.Control
                    {...register(`name_${item.char_code.toLowerCase()}`)}
                    type="text"
                    placeholder={`${t('name_errors')} ${item.char_code}`}
                  />
                </Form.Group>
              </Col>
            </Row>
          ))}

        <Row className="mb-3">
          <Col>
            <Form.Group className="md-4">
              <Form.Label>{t('status_code')}</Form.Label>
              <Form.Control
                {...register('status_code')}
                type="number"
                placeholder={t('status_code')}
              />
            </Form.Group>
          </Col>
        </Row>

        <Button
          className="w-100"
          variant="primary"
          type="submit"
          loading={loading}
        >
          {t('add')}
        </Button>
      </Form>
    </div>
  );
};

export default CreateErrors;
