/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import languageService from 'services/language';
import { showAlert } from 'store/alert/alert.thunk';
import inventoryStatusService from 'services/inventoryStatus';

interface CreateInventoryProps {
  setOpenInventory: (show: boolean) => void;
  selectedInventoryId?: any;
  refetch?: any;
}

const CreateInventory = ({
  setOpenInventory,
  selectedInventoryId,
  refetch
}: CreateInventoryProps) => {
  const { t } = useTranslation();

  const options = [
    {
      value: true,
      label: t('active')
    },
    {
      value: false,
      label: t('blocked')
    }
  ];
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, control, reset } = useForm();

  const dispatch: Dispatch<any> = useDispatch();

  //GET Lang
  const { data: langData } = useQuery(['GET_LANGUAGE'], () => {
    const res = languageService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.languages);
    return res;
  });

  //Get by id
  const fetchData = () => {
    if (selectedInventoryId === '') return setLoading(false);

    inventoryStatusService
      .getInventoryById(selectedInventoryId)
      .then((res: any) => {
        const nameObj: any = {};
        Object.keys(res.name).forEach(lang => {
          nameObj[`name_${lang}`] = res.name[lang];
        });

        const computed = {
          ...nameObj,
          status: options.find(option => option.value === res?.status)
        };
        reset(computed);
      })
      .finally(() => setLoading(false));
  };

  const onSubmit: SubmitHandler<FieldValues> = data => {
    if (selectedInventoryId !== '') return update(data);
    createData(data);
  };

  const createData = (data: any) => {
    setLoading(true);

    const dynamicKeys = Object.keys(data).filter(key =>
      key.startsWith('name_')
    );
    const nameObj: any = {};

    dynamicKeys.forEach(key => {
      const lang = key.split('_')[1];
      nameObj[lang] = data[key];
    });

    const createdData = {
      name: nameObj,
      status: data?.status.value
    };

    inventoryStatusService
      .createInventory(createdData)
      .then((res: any) => {
        if (res) {
          dispatch(
            showAlert({
              title: `${t('inventory_status')} ${t('successfully_added')}`,
              type: 'success'
            })
          );
        }
      })
      .finally(() => {
        refetch();
        setOpenInventory(false);
        setLoading(false);
      });
  };

  const update = (data: any) => {
    setLoading(true);

    const dynamicKeys = Object.keys(data).filter(key =>
      key.startsWith('name_')
    );
    const nameObj: any = {};

    dynamicKeys.forEach(key => {
      const lang = key.split('_')[1];
      nameObj[lang] = data[key];
    });

    const updateData = {
      name: nameObj,
      status: data?.status.value,
      id: selectedInventoryId
    };
    inventoryStatusService
      .updateInventory(selectedInventoryId, updateData)
      .finally(() => {
        refetch();
        setLoading(false);
        setOpenInventory(false);
      });
  };

  useEffect(() => {
    if (selectedInventoryId !== '') {
      fetchData();
    }
  }, [langData, selectedInventoryId]);

  return (
    <div>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="bg-white rounded"
      >
        {langData &&
          langData.map((item: any) => (
            <Row key={item.id} className="mb-3">
              <Col>
                <Form.Group className="md-4">
                  <Form.Label>
                    {t('name_inventory_status')} ( {item.char_code} )
                  </Form.Label>
                  <Form.Control
                    {...register(`name_${item.char_code.toLowerCase()}`)}
                    type="text"
                    placeholder={`${t('name_inventory_status')} ${
                      item.char_code
                    }`}
                  />
                </Form.Group>
              </Col>
            </Row>
          ))}

        <Row className="mb-3">
          <Col>
            <Form.Label>{t('status')}</Form.Label>
            <div className="react-select-container">
              <Controller
                name="status"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    placeholder={t('select')}
                    options={options}
                    styles={{
                      control: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      singleValue: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      menuPortal: base => ({
                        ...base,
                        fontSize: '12.8px'
                      })
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>

        <Button
          className="w-100"
          variant="primary"
          type="submit"
          loading={loading}
        >
          {t('add')}
        </Button>
      </Form>
    </div>
  );
};

export default CreateInventory;
