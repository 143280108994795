import axios from 'axios';
import i18n from 'i18next';

import { store } from 'store';
import { showAlert } from 'store/alert/alert.thunk';
import authService from 'services/auth/authService';
import { authActions } from 'store/auth/auth.slice';

const requestAuth = axios.create({
  baseURL: process.env.REACT_APP_URL_AUTH,
  timeout: 100000
});

const errorHandler = error => {
  const currentLanguage = i18n.language;
  const status = error?.response?.status;
  const refresh_token = store.getState().auth.token;

  if (error.response?.data) {
    store.dispatch(
      showAlert({ title: error.response?.data?.data?.info?.[currentLanguage] })
    );
  } else if (status === 401 && refresh_token) {
    const data = {
      token: refresh_token
    };

    const originalRequest = error.config;

    authService
      .refreshTokenFn(data)
      .then(res => {
        console.log('refres res', res);
        store.dispatch(authActions.setToken(res?.data?.access_token));
        window?.location?.reload();
        return requestAuth(originalRequest);
      })
      .catch(error => {
        store.dispatch(authActions.logout());
        return Promise.reject(error);
      });
  } else {
    store.dispatch(showAlert({ title: '___ERROR___' }));
  }

  return Promise.reject(error);
};

requestAuth.interceptors.request.use(
  config => {
    const token = store.getState().auth.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },

  error => errorHandler(error)
);

requestAuth.interceptors.response.use(response => response.data, errorHandler);

export default requestAuth;
