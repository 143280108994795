/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import { showAlert } from 'store/alert/alert.thunk';
import languageService from 'services/language';
import applicationBonusService from 'services/applicaationBonus';

interface CreateApplicationBonusProps {
  setOpenBonus: (show: boolean) => void;
  selectedBonusId?: any;
  refetch?: any;
  setSelectedBonusId?: any;
}

const CreateApplicationBonus = ({
  setOpenBonus,
  selectedBonusId,
  refetch,
  setSelectedBonusId
}: CreateApplicationBonusProps) => {
  const { t } = useTranslation();

  const options = [
    {
      value: true,
      label: t('active')
    },
    {
      value: false,
      label: t('blocked')
    }
  ];
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm();

  const dispatch: Dispatch<any> = useDispatch();
  const inputRef = useRef<HTMLInputElement | any>();

  //GET Lang
  const { data: langData } = useQuery(['GET_LANGUAGE'], () => {
    const res = languageService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.languages);
    return res;
  });

  const fetchData = () => {
    if (selectedBonusId === '') return setLoading(false);

    applicationBonusService
      .getApplicationBonusById(selectedBonusId)
      .then((res: any) => {
        const nameObj: any = {};
        Object.keys(res.name).forEach(lang => {
          nameObj[`name_${lang}`] = res.name[lang];
        });

        const computed = {
          ...nameObj,
          status: res?.status ?? false
        };
        reset(computed);
      })
      .finally(() => setLoading(false));
  };

  const onSubmit: SubmitHandler<FieldValues> = data => {
    if (selectedBonusId !== '') return update(data);
    createData(data);
  };

  const createData = (data: any) => {
    setLoading(true);

    const dynamicKeys = Object.keys(data).filter(key =>
      key.startsWith('name_')
    );
    const nameObj: any = {};

    dynamicKeys.forEach(key => {
      const lang = key.split('_')[1];
      nameObj[lang] = data[key];
    });

    const createdData = {
      name: nameObj,
      status: data?.status === 'true' ? true : false
    };

    applicationBonusService
      .createApplicationBonus(createdData)
      .then((res: any) => {
        if (res) {
          dispatch(
            showAlert({
              title: `${t('bonus_application')} ${t('successfully_added')}`,
              type: 'success'
            })
          );
          refetch();
          setOpenBonus(false);
          setLoading(false);
          setSelectedBonusId('');
        }
      });
  };

  const update = (data: any) => {
    setLoading(true);

    const dynamicKeys = Object.keys(data).filter(key =>
      key.startsWith('name_')
    );
    const nameObj: any = {};

    dynamicKeys.forEach(key => {
      const lang = key.split('_')[1];
      nameObj[lang] = data[key];
    });

    const updateData = {
      name: nameObj,
      status: data?.status === 'true' || data?.status === true ? true : false,
      id: selectedBonusId
    };
    applicationBonusService
      .updateApplicationBonus(selectedBonusId, updateData)
      .finally(() => {
        refetch();
        setLoading(false);
        setOpenBonus(false);
        setSelectedBonusId('');
      });
  };

  useEffect(() => {
    if (selectedBonusId !== '') {
      fetchData();
    }
  }, [langData, selectedBonusId]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      setTimeout(() => {
        inputRef.current.select();
      }, 500);
    }
  }, [inputRef.current, langData]);

  return (
    <>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      >
        {langData &&
          langData.map((item: any, index: number) => (
            <Row key={item.id} className="mb-3">
              <Col>
                <Controller
                  name={`name_${item.char_code.toLowerCase()}`}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Form.Floating className="md-4">
                      <Form.Control
                        {...field}
                        ref={index === 0 ? inputRef : undefined}
                        type="text"
                        placeholder={`${t('name_bonus_application')} ${
                          item.char_code
                        }`}
                        onFocus={e => e.target.select()}
                        isInvalid={
                          !!errors[`name_${item.char_code.toLowerCase()}`]
                        }
                      />
                      <label htmlFor="floatingInputCustom">
                        {t('name_bonus_application')} ( {item.char_code} )
                      </label>
                      {errors[`name_${item.char_code.toLowerCase()}`] && (
                        <Form.Control.Feedback type="invalid">
                          {t('required_field')}
                        </Form.Control.Feedback>
                      )}
                    </Form.Floating>
                  )}
                />
              </Col>
            </Row>
          ))}

        <Row className="mb-3">
          <Col>
            <div className="react-select-container">
              <Controller
                name="status"
                control={control}
                rules={{ required: true }}
                defaultValue=""
                render={({ field }) => (
                  <Form.Floating>
                    <Form.Select {...field} isInvalid={!!errors.status}>
                      <option className="d-none" value=""></option>
                      {options?.map((option: any) => (
                        <option
                          key={option.value}
                          value={option.value}
                          className="option"
                        >
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>

                    <label htmlFor="floatingInputCustom">{t('status')}</label>
                    {errors?.status && (
                      <span
                        style={{
                          marginTop: '2px',
                          color: 'red',
                          fontSize: '12px'
                        }}
                      >
                        {t('required_field')}
                      </span>
                    )}
                  </Form.Floating>
                )}
              />
            </div>
          </Col>
        </Row>

        <Button
          style={{ height: '48px', marginTop: 'auto' }}
          variant="primary"
          type="submit"
          loading={loading}
        >
          {t('add')}
        </Button>
      </Form>
    </>
  );
};

export default CreateApplicationBonus;
