import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const regionBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'directories',
    url: '/'
  },
  {
    label: 'region',
    active: true
  }
];

export type RegionsType = {
  country: string;
  region: string;
  status: string;
  guid: string;
};
