/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import { showAlert } from 'store/alert/alert.thunk';
import languageService from 'services/language';
import categoryService from 'services/category';
import activityService from 'services/activity';

interface CreateSubCategoryProps {
  setOpenSubCategory: (show: boolean) => void;
  selectedSubCategoryId?: any;
  refetch?: any;
}

const CreateSubCategory = ({
  setOpenSubCategory,
  selectedSubCategoryId,
  refetch
}: CreateSubCategoryProps) => {
  const { t, i18n } = useTranslation();

  const options = [
    {
      value: true,
      label: t('active')
    },
    {
      value: false,
      label: t('blocked')
    }
  ];
  const [loading, setLoading] = useState(false);
  const [activityOption, setActivityOption] = useState([]);

  const { register, handleSubmit, control } = useForm();

  const dispatch: Dispatch<any> = useDispatch();

  //GET Lang
  const { data: langData } = useQuery(['GET_LANGUAGE'], () => {
    const res = languageService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.languages);
    return res;
  });

  //GET Activity to options
  useQuery(['GET_ACTIVITY_KIND'], async () => {
    await activityService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => {
        const options = res?.activity_kinds?.map((activity: any) => ({
          value: activity.id,
          label: activity.name?.[i18n?.language]
        }));
        setActivityOption(options);
      });
  });

  const onSubmit: SubmitHandler<FieldValues> = data => {
    createData(data);
  };

  const createData = (data: any) => {
    setLoading(true);

    const dynamicKeys = Object.keys(data).filter(key =>
      key.startsWith('name_')
    );
    const nameObj: any = {};

    dynamicKeys.forEach(key => {
      const lang = key.split('_')[1];
      nameObj[lang] = data[key];
    });

    const createdData = {
      name: nameObj,
      parent: data?.parents_category,
      activity_kind_id: data?.activity?.value,
      description: data?.text,
      status: data?.status.value,
      parent_id: selectedSubCategoryId
    };

    categoryService
      .createCategory(createdData)
      .then((res: any) => {
        if (res) {
          dispatch(
            showAlert({
              title: 'Категория успешно добавлен!',
              type: 'success'
            })
          );
        }
      })
      .finally(() => {
        refetch();
        setOpenSubCategory(false);
        setLoading(false);
      });
  };

  return (
    <div>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="bg-white rounded"
      >
        <Row className="mb-3">
          <Col>
            <Form.Label>{t('type_activity')}</Form.Label>
            <div className="react-select-container">
              <Controller
                name="activity"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    placeholder={t('select')}
                    options={activityOption}
                    styles={{
                      control: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      singleValue: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      menuPortal: base => ({
                        ...base,
                        fontSize: '12.8px'
                      })
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>

        {langData &&
          langData.map((item: any) => (
            <Row key={item.id} className="mb-3">
              <Col>
                <Form.Group className="md-4">
                  <Form.Label>
                    {t('name_category')} ( {item.char_code} )
                  </Form.Label>
                  <Form.Control
                    {...register(`name_${item.char_code.toLowerCase()}`)}
                    type="text"
                    placeholder={`${t('name_category')} ${item.char_code}`}
                  />
                </Form.Group>
              </Col>
            </Row>
          ))}

        <Row className="mb-3">
          <Col>
            <Form.Group className="md-4">
              <Form.Label>{t('description')}</Form.Label>
              <Form.Control
                {...register('text')}
                type="text"
                placeholder="Text"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Label>{t('status')}</Form.Label>
            <div className="react-select-container">
              <Controller
                name="status"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    placeholder={t('select')}
                    options={options}
                    styles={{
                      control: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      singleValue: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      menuPortal: base => ({
                        ...base,
                        fontSize: '12.8px'
                      })
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>

        <Button
          className="w-100"
          variant="primary"
          type="submit"
          loading={loading}
        >
          {t('add')}
        </Button>
      </Form>
    </div>
  );
};

export default CreateSubCategory;
