import axios from 'axios';
import i18n from 'i18next';

import { store } from 'store';
import { showAlert } from 'store/alert/alert.thunk';
import { authActions } from 'store/auth/auth.slice';
import authService from 'services/auth/authService';

const request = axios.create({
  baseURL: process.env.REACT_APP_URL_BASE,
  timeout: 100000
});

const errorHandler = error => {
  const status = error?.response?.status;
  const refresh_token = store.getState().auth.token;
  const currentLanguage = i18n.language;

  if (status === 403) {
    store.dispatch(
      showAlert({
        title: `Forbidden: ${error.response?.data?.data?.info?.[currentLanguage]}`
      })
    );

    store.dispatch(authActions.logout());
  } else if (status === 401 && refresh_token) {
    const data = {
      token: refresh_token
    };

    const originalRequest = error.config;

    authService
      .refreshTokenFn(data)
      .then(res => {
        console.log('refres res', res);
        store.dispatch(authActions.setToken(res?.data?.access_token));
        window?.location?.reload();
        return request(originalRequest);
      })
      .catch(error => {
        console.log('error refresh', error);
        store.dispatch(authActions.logout());
        return Promise.reject(error);
      });
  } else if (status === 400) {
    store.dispatch(
      showAlert({
        title: `${error.response?.data?.data?.info?.[currentLanguage]}`
      })
    );
  } else if (status === 500) {
    store.dispatch(
      showAlert({
        title: `${error.response?.data?.data?.info?.[currentLanguage]}`,
        type: 'error'
      })
    );
  } else store.dispatch(showAlert({ title: '___ERROR___' }));

  return Promise.reject(error);
};

request.interceptors.request.use(
  config => {
    const token = store.getState().auth.token;
    // const token =
    //   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTY0NDEyNjIsImlhdCI6MTcxNjQ0MTIwMiwiaWQiOiI1Mjc3OTE3MC1kOGM4LTQ0ZDItOWExMC1kNTg3YTljNDQ0ZTUiLCJsZWdhbF9lbnRpdHlfaWQiOiI3MTU4Mjg4Ny1jZDI5LTQ1YTEtYTA5Mi1mNjg0ZDZiY2QyZTciLCJyb2xlX2lkIjoiMWMzNGJmM2YtMjhlNy00MWQ4LTllNjgtMGUzODcwYTFhNTU5IiwidXNlcl9pZCI6IjJhOTRlODM0LTM3MWItNGNkZC05Yjg4LWYwMWM4NGRkNmI2YyJ9.PnXKcwCRD1-nNwHeoZmgYshyAjb9LqTnV0VV43fBups';
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },

  error => errorHandler(error)
);

request.interceptors.response.use(response => response.data.data, errorHandler);

export default request;
