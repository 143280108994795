import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const paymentBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'directories',
    url: '/'
  },
  {
    label: 'payment_type',
    active: true
  }
];

export type PaymentType = {
  name: string;
  count: string;
  country: string;
  units: string;
  show: string;
  lang: string;
  status: string;
  guid: string;
  photo: string;
};
