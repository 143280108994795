/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Row, Col } from 'react-bootstrap';
import {
  useForm,
  FieldValues,
  SubmitHandler,
  Controller
} from 'react-hook-form';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import { showAlert } from 'store/alert/alert.thunk';
import languageService from 'services/language';
import rolesService from 'services/roles';

interface CreateUsersTypeProps {
  setOpenRole: (show: boolean) => void;
  selectedRoleId?: any;
  refetch?: any;
}

const CreateUsersType = ({
  setOpenRole,
  selectedRoleId,
  refetch
}: CreateUsersTypeProps) => {
  const { t } = useTranslation();

  const options = [
    {
      value: true,
      label: t('active')
    },
    {
      value: false,
      label: t('blocked')
    }
  ];
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, control, reset } = useForm();

  const dispatch: Dispatch<any> = useDispatch();

  //GET Lang
  const { data: langData } = useQuery(['GET_LANGUAGE'], () => {
    const res = languageService
      .getList({ offset: 0, limit: 100 })
      .then((res: any) => res?.languages);
    return res;
  });

  //Get by id
  const fetchData = () => {
    if (selectedRoleId === '') return setLoading(false);

    rolesService
      .getRoleById(selectedRoleId)
      .then((res: any) => {
        const computed = {
          status: options.find(
            option => option.value === res?.data?.system_access
          ),
          name: res?.data?.name
        };
        reset(computed);
      })
      .finally(() => setLoading(false));
  };

  const onSubmit: SubmitHandler<FieldValues> = data => {
    if (selectedRoleId !== '') return update(data);
    createData(data);
  };

  const createData = (data: any) => {
    setLoading(true);

    const createdData = {
      name: data?.name,
      system_access: data?.status.value,
      system_level: 'HOLDING'
    };

    rolesService
      .createRole(createdData)
      .then((res: any) => {
        if (res) {
          dispatch(
            showAlert({
              title: `${t('users_type')} ${t('successfully_added')}`,
              type: 'success'
            })
          );
        }
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        refetch();
        setOpenRole(false);
        setLoading(false);
      });
  };

  const update = (data: any) => {
    setLoading(true);

    const updateData = {
      name: data?.name,
      system_access: data?.status.value,
      system_level: 'HOLDING',
      id: selectedRoleId
    };
    rolesService
      .updateRole(selectedRoleId, updateData)
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        refetch();
        setLoading(false);
        setOpenRole(false);
      });
  };

  useEffect(() => {
    if (selectedRoleId !== '') {
      fetchData();
    }
  }, [langData, selectedRoleId]);

  return (
    <div>
      <Form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="bg-white rounded"
      >
        <Row className="mb-3">
          <Col>
            <Form.Group className="md-4">
              <Form.Label>{t('user_type_name')}</Form.Label>
              <Form.Control
                {...register(`name`)}
                type="text"
                placeholder={t('user_type_name')}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Label>{t('status')}</Form.Label>
            <div className="react-select-container">
              <Controller
                name="status"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    closeMenuOnSelect={true}
                    classNamePrefix="react-select"
                    placeholder={t('select')}
                    options={options}
                    styles={{
                      control: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      singleValue: base => ({
                        ...base,
                        fontSize: '12.8px'
                      }),
                      menuPortal: base => ({
                        ...base,
                        fontSize: '12.8px'
                      })
                    }}
                  />
                )}
              />
            </div>
          </Col>
        </Row>

        <Button
          className="w-100"
          variant="primary"
          type="submit"
          loading={loading}
        >
          {t('add')}
        </Button>
      </Form>
    </div>
  );
};

export default CreateUsersType;
