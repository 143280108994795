/* eslint-disable @typescript-eslint/no-explicit-any */
import request from 'utils/request';

const errorsService = {
  getList: (params: any) => request.get('/guidebook/custom-error', { params }),
  getErrorsById: (id: any) => request.get(`/guidebook/custom-error/${id}`),
  createErrors: (data: any) => request.post('/guidebook/custom-error', data),
  deleteErrors: (id: any) => request.delete(`/guidebook/custom-error/${id}`),
  updateErrors: (id: any, data: any) =>
    request.put(`/guidebook/custom-error/${id}`, data)
};

export default errorsService;
